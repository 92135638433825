body {
  font-family: SF-Compact-Display-Medium;
  font-size: 16px;
}

.team_user_create .team_user-Block h1 {
  font-size: 18px;
  font-family: SF-Compact-Display-Medium;
  margin-bottom: 0;
}
.team_user_create .team_user-header {
  border: 1px solid #cbcbcd;
  border-radius: 5px;
}
.team_user_create .team_user-heading {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: #e0e0e0;
  padding: 15px 30px;
}
.team_user_create .bi {
  vertical-align: -0.125em;
  fill: currentColor;
}
.team_user_create div.nav button.nav-link {
  text-align: left;
  color: black;
}
.team_user_create div.nav button.nav-link.active {
  color: white;
}
.team_user_create .admin-main-section {
  background: white;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
}
.team_user_create .admin-main-section .nav {
  width: 25%;
  padding: 1rem 0;
}
.team_user_create .projects-block .label {
  font-size: 16px;
  font-family: SF-Compact-Display-Regular;
  margin-bottom: 0;
}
.team_user_create .projects-block .col-md-6 {
  padding: 50px 20px;
}
.team_user_create .projects-block .col-md-6 .row {
  padding: 10px 0;
}
.team_user_create .projects-block .col-md-6 .row input,
.team_user_create .projects-block .col-md-6 .row select {
  width: 100%;
}
.team_user_create .projects-block .form-select-sm,
.team_user_create .projects-block input {
  border: 1px solid #e8e8e8;
}
.team_user_create .rate {
  width: 40% !important;
  padding: 2.5px;
}
.team_user_create .currency {
  width: 55% !important;
  border-radius: 0;
}
.team_user_create .checkbox {
  width: 12px !important;
  height: 12px !important;
  float: left !important;
  margin-right: 0 !important;
  margin-left: 0 !important;
  border-radius: 0 !important;
}
.team_user_create .form-check label {
  padding-left: 20px;
  display: block;
  font-size: 14px;
  font-family: SF-Compact-Display-Regular;
  margin-bottom: 0;
}
.team_user_create .projects-block .form-select-sm option {
  font-size: 14px;
  font-family: SF-Compact-Display-Regular;
  margin-bottom: 0;
}
.team_user_create .save-btn {
  float: right;
}
.team_user_create .save-btn button {
  font-size: 16px;
  font-family: SF-Compact-Display-Medium;
  color: #fff;
  background-color: #0085e0 !important;
  border-color: #0085e0 !important;
  padding: 5px 20px;
  float: right;
}
.team_user_create .save-btn button:active,
.team_user_create .save-btn button:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: 0 !important;
  border: none !important;
}
.team_user_create .container.bg-white {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.team_user_create .copy-writes .text-muted {
  font-size: 16px;
  font-family: SF-Compact-Display-Light;
  color: #000 !important;
  font-weight: 600;
}
.team_user_create .copy-writes .text-muted1 {
  font-size: 9px;
  font-family: SF-Compact-Display-Thin;
  color: #000 !important;
  font-weight: 600;
}
.team_user_create .copy-writes .col-md-4 {
  line-height: 1;
}
.team_user_create .copy-writes footer .footer-icon {
  margin: 0 !important;
  vertical-align: top;
}
.team_user_create .copy-writes footer ul li.ms-3 {
  font-size: 10px;
  font-family: SF-Compact-Display-Thin;
  font-weight: 600;
  color: #000;
}
.team_user_create .admin-main-section button {
  width: 100%;
}
.team_user_create .admin-main-section a {
  text-decoration: none;
}
.team_user_create .admin-main-section a:hover {
  text-decoration: none;
}
