body {
  font-family: SF-Compact-Display-Medium;
  font-size: 16px;
}
.add_services .add_services-Block h1 {
  font-size: 18px;
  font-family: SF-Compact-Display-Medium;
  margin-bottom: 0;
}
.add_services .add_services-header {
  border: 1px solid #cbcbcd;
  border-radius: 5px;
}
.add_services .add_services-heading {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: #e0e0e0;
  padding: 15px 30px;
}
.add_services .bi {
  vertical-align: -0.125em;
  fill: currentColor;
}
.add_services div.nav button.nav-link {
  text-align: left;
  color: black;
}
.add_services div.nav button.nav-link.active {
  color: white;
}
.add_services .admin-main-section {
  background: white;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
}
.add_services .admin-main-section .nav {
  width: 25%;
  padding: 1rem 0;
}
.add_services .modal-title {
  font-size: 18px;
  font-family: SF-Compact-Display-Medium;
  margin-bottom: 0;
}
.add_services .modal-header {
  padding: 10px 15px;
  background: #d2d2d2;
}
.add_services .modal-body .label {
  font-size: 18px;;
  font-family: SF-Compact-Display-Medium;
  margin-bottom: 0;
}
.add_services .modal-body .row {
  padding: 10px 0;
}
.add_services .modal-body .form-select-sm,
.add_services .modal-body input {
  border: 1px solid #e8e8e8;
}
.add_services .modal-body .form-select-sm {
  width: 85%;
}
.add_services .modal-body .form-select-sm option {
  font-size: 14px;
  font-family: SF-Compact-Display-Regular;
  margin-bottom: 0;
}
.add_services .modal-body input {
  font-size: 14px;
  font-family: SF-Compact-Display-Regular;
  margin-bottom: 0;
}
.add_services .modal-body span {
  font-size: 14px;
  font-weight: 500;
  font-family: SF-Compact-Display-Medium;
  margin-bottom: 0;
  margin-left: 5px;
}
.add_services .modal-header button {
  background: none;
  border: none;
}
.add_services .modal-header img {
  display: block;
}
.add_services .modal-footer {
  border: none;
  padding: 10px 10px;
}
.add_services .add_servicess-list-block td {
  font-size: 14px;
  font-family: SF-Compact-Display-Medium;
  margin-bottom: 0;
  border-bottom-width: 0;
}
.add_services .modal-header button {
  margin: 0 !important;
}
.add_services .save-btn {
  float: right;
}
.add_services .save-btn button {
  font-size: 16px;
  font-family: SF-Compact-Display-Medium;
  color: #fff;
  background-color: #0085e0 !important;
  border-color: #0085e0 !important;
  padding: 5px 20px;
}
.add_services .save-btn button:active,
.add_services .save-btn button:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: 0 !important;
  border: none !important;
}
.add_services .add button:active,
.add_services .add button:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: 0 !important;
  border: none !important;
}
.add_services .add_services-heading .add button {
  font-size: 14px;
  font-family: SF-Compact-Display-Regular;
  background: #0b55b0;
  text-align: center;
  color: #fff;
  margin-bottom: 0;
  border: none;
  border-radius: 0;
  padding: 3px 5px;
}
.add_services .add_servicess-list-block {
  margin: 0 0 190px 0;
}
.add_services table.table {
  margin-bottom: 0;
}
.add_services .table-add_services-heading {
  font-size: 18px;;
  font-family: SF-Compact-Display-Medium;
  margin-bottom: 0;
}
.add_services .container.bg-white {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.add_services
  .projects-block
  .add_servicess-list-block
  .table-add_services-heading {
  font-size: 14px;
  font-family: SF-Compact-Display-Semibold;
  margin-bottom: 0;
}
.add_services .table .table-group-divider {
  border: none;
}
.add_services .projects-block .add_servicess-list-block .table-group-divider {
  border-top-color: #aeaeae;
}
.add_services th {
  vertical-align: middle;
  padding: 10px 20px !important;
}
.add_services td {
  vertical-align: middle;
  padding: 20px 30px !important;
}
.add_services .copy-writes .text-muted {
  font-size: 16px;
  font-family: SF-Compact-Display-Light;
  color: #000 !important;
  font-weight: 600;
}
.add_services .copy-writes .text-muted1 {
  font-size: 9px;
  font-family: SF-Compact-Display-Thin;
  color: #000 !important;
  font-weight: 600;
}
.add_services .copy-writes .col-md-4 {
  line-height: 1;
}
.add_services .copy-writes footer .footer-icon {
  margin: 0 !important;
  vertical-align: top;
}
.add_services .copy-writes footer ul li.ms-3 {
  font-size: 10px;
  font-family: SF-Compact-Display-Thin;
  font-weight: 600;
  color: #000;
}
.add_services .table thead {
  background: #f5f5f5;
}
.add_services .table tbody tr:nth-child(even) {
  background-color: #f5f5f5;
}
.add_services th,
.add_services td {
  text-align: center;
}
.add_services th:nth-child(1),
.add_services td:nth-child(1) {
  text-align: left;
}
.add_services .table tbody td span.cc {
  font-size: 14px;
  background: #fdfdfd;
  padding: 2px 10px;
  font-family: SF-Compact-Display-Medium;
  color: #000;
}
.add_services .admin-main-section button {
  width: 100%;
}
.add_services .admin-main-section a {
  text-decoration: none;
}
.add_services .admin-main-section a:hover {
  text-decoration: none;
}
