body {
  font-family: SF-Compact-Display-Medium;
  font-size: 16px;
}
.client_list .client_list-Block h1 {
  font-size: 18px;
  font-family: SF-Compact-Display-Medium;
  margin-bottom: 0;
  font-weight: bold;
}
.client_list .client_list-header {
  border: 1px solid #cbcbcd;
  border-radius: 5px;
}
.client_list .client_list-heading {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: #e0e0e0;
  padding: 15px 30px;
}
.client_list .client_lists-list-block td {
  font-size: 14px;
  font-family: SF-Compact-Display-Medium;
  margin-bottom: 0;
  border-bottom-width: 0;
}
.client_list .client_list-heading .add p {
  width: 90px;
  font-size: 14px;
  font-family: SF-Compact-Display-Regular;
  background: #0b55b0;
  text-align: center;
  color: #fff;
  margin-bottom: 0;
}
.client_list .client_lists-list-block {
  margin: 0 18px;
}
.client_list table.table {
  margin-bottom: 0;
}
.client_list .table-client_list-heading {
  font-size: 18px;
  font-family: SF-Compact-Display-Medium;
  margin-bottom: 0;
}
.client_list .container.bg-white {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.client_list
  .projects-block
  .client_lists-list-block
  .table-client_list-heading {
  font-size: 14px;
  margin-bottom: 0;
  font-weight: bold;
}
.client_list .table.shadow-sm .table-group-divider {
  border: none;
}
.client_list .projects-block .client_lists-list-block .table-group-divider {
  border-top-color: #aeaeae;
}
.client_list th {
  vertical-align: middle;
  padding: 10px 20px !important;
}
.client_list td {
  vertical-align: middle;
  padding: 20px 20px !important;
}
.client_list .copy-writes .text-muted {
  font-size: 16px;
  font-family: SF-Compact-Display-Light;
  color: #000 !important;
  font-weight: 600;
}
.client_list .copy-writes .text-muted1 {
  font-size: 9px;
  font-family: SF-Compact-Display-Thin;
  color: #000 !important;
  font-weight: 600;
}
.client_list .copy-writes .col-md-4 {
  line-height: 1;
}
.client_list .copy-writes footer .footer-icon {
  margin: 0 !important;
  vertical-align: top;
}
.client_list .copy-writes footer ul li.ms-3 {
  font-size: 10px;
  font-family: SF-Compact-Display-Thin;
  font-weight: 600;
  color: #000;
}
.client_list .inside-table {
  padding: 20px 30px 300px 30px;
}
.client_list .table.shadow-sm {
  border: 1px solid #e8e8e8;
}
.client_list .table.shadow-sm thead {
  background: #f5f5f5;
}
.client_list .table.shadow-sm tbody tr:nth-child(even) {
  background-color: #f5f5f5;
}
.client_list .table.shadow-sm tbody tr:hover td {
  background: #d3d3d3;
}
.client_list .table.shadow-sm tbody td span.cc {
  font-size: 12px;
  background: #1e9dbd;
  padding: 3px 5px;
  font-family: SF-Compact-Display-Regular;
  color: #fff;
}
.client_list .table.shadow-sm tbody td span.eccomerce {
  padding: 2px;
  margin-bottom: 4px;
  border: 1px solid grey;
  border-radius: 3px;
  background: white;
  margin-right: 5px;
  color: grey;
  display: inline-flex;
  flex-flow: row wrap;
  gap: 10px;
  font-size: 12px;
}
.client_list .table.shadow-sm tbody td span.marketing {
  font-size: 16px;
  background: #12a2b7;
  padding: 5px 5px;
  font-family: SF-Compact-Display-Regular;
  color: #fff;
}
.client_list .table.shadow-sm tbody td span.graphic-design {
  font-size: 16px;
  background: #ee6418;
  padding: 5px 5px;
  font-family: SF-Compact-Display-Regular;
  color: #fff;
}
@media (min-width: 992px) {
  .client_list .projects-block .col-md-11 {
    padding-right: 50px;
  }
}
