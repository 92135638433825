@font-face {
  font-family: SF-Compact-Display-Medium;
  src: url(../../public/assests/font/FontsFree-Net-SF-Compact-Display-Medium.ttf);
}
@font-face {
  font-family: SF-Compact-Display-Regular;
  src: url(../../public/assests/font/FontsFree-Net-SFCompactDisplay-Regular.ttf);
}
@font-face {
  font-family: Inter-Regular;
  src: url(../../public/assests/font/Inter-Regular.otf);
}
body {
  background-image: url(../../public/assests/img/login-bg.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100vh;
}
.form-control:focus {
  border-color: #ced4da !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.logo img {
  height: 25px !important;
}
.logo {
  display: flex;
  justify-content: center;
  padding: 30px 0 40px 0;
}
.logo p {
  font-family: SF-Compact-Display-Medium;
  font-size: 16px;
  margin-top: 7px;
  margin-left: -8px;
}
/* input {
      background-color: #fdfdfd !important;
      border: 1px solid #e8e8e8 !important;
      height: 42px;
  } */
.login-block {
  width: 600px;
  background: #fff;
  padding: 20px 50px;
  border-radius: 5px;
  margin: auto;
}
.login-block input,
.login-block label {
  font-family: SF-Compact-Display-Regular;
  font-size: 18px;;
}
.float_right {
  float: right;
}
.login-block.shadow-sm {
  box-shadow: 0 0.125rem 1rem rgba(0, 0, 0, 0.125) !important;
  top: 50%;
  left: 50%;
  position: fixed;
  transform: translate(-50%, -50%);
  width: 600px;
}
.login-block.shadow-sm.em {
  box-shadow: none !important;
  top: 85%;
  left: 50%;
  position: fixed;
  transform: translate(-50%, -50%);
  width: 600px;
  background: 0 0;
  padding: 0;
}
.login-block.shadow-sm.em p {
  font-size: 14px;
  font-family: SF-Compact-Display-Regular;
}
.login-block button {
  border-radius: 25px;
  font-family: SF-Compact-Display-Regular;
  font-size: 16px;
  padding: 5px 25px;
  background-image: linear-gradient(#5dc3ec, #2364a8);
  border: none;
}
.login-block button:active,
.login-block button:focus {
  border-color: #ced4da !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: 0 !important;
  border: none !important;
}
