.notifi-elipse {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /* -webkit-line-clamp: 2;
  line-clamp: 2; */
  -webkit-box-orient: vertical;
}
.notification-div:hover {
  background: #d3d3d3;
}
.Notiouter{
  position:relative;
  }
  .Notioverlap{
  position: relative;
  z-index: 1;
  }
  .Notioverlap{
  position: absolute;
  z-index: 2;
  top: 0;
  display: none;
  left: 96%;	
  }
  .Notiouter:hover .Notioverlap{ 
  display:block;
  }
