body {
  font-family: SF-Compact-Display-Medium;
  font-size: 16px;
}

.team_index-Block h1 {
  font-size: 18px;
  font-family: SF-Compact-Display-Medium;
  margin-bottom: 0;
}
.team_index .team_index-header {
  border: 1px solid #cbcbcd;
  border-radius: 5px;
}
.team_index .team_index-heading {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: #e0e0e0;
  padding: 15px 10px;
}
.team_index .bi {
  vertical-align: -0.125em;
  fill: currentColor;
}
.team_index div.nav button.nav-link {
  text-align: left;
  color: black;
}
.team_index div.nav button.nav-link.active {
  color: white;
}
.team_index .admin-main-section {
  background: white;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
}
.team_index .admin-main-section .nav {
  width: 25%;
  padding: 1rem 0;
}
.team_index .team_indexs-list-block td {
  font-size: 14px;
  font-family: SF-Compact-Display-Regular;
  margin-bottom: 0;
  border-bottom-width: 0;
}
.team_index .add button:active,
.team_index .add button:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: 0 !important;
  border: none !important;
}
.team_index .team_index-heading .add button {
  font-size: 14px;
  font-family: SF-Compact-Display-Regular;
  background: #0b55b0;
  text-align: center;
  color: #fff;
  margin-bottom: 0;
  border: none;
  border-radius: 0;
  padding: 3px 5px;
}
.team_index .team_indexs-list-block {
  margin: 0 0 190px 0;
}
.team_index table.table {
  margin-bottom: 0;
}
.team_index .table-team_index-heading {
  font-size: 18px;;
  font-family: SF-Compact-Display-Medium;
  margin-bottom: 0;
}
.team_index .container.bg-white {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.team_index .projects-block .team_indexs-list-block .table-team_index-heading {
  font-size: 16px;
  font-family: SF-Compact-Display-Medium;
  margin-bottom: 0;
}
.team_index .table .table-group-divider {
  border: none;
}
.team_index .projects-block .team_indexs-list-block .table-group-divider {
  border-top-color: #aeaeae;
}
.team_index th {
  vertical-align: middle;
  padding: 10px 10px !important;
}
.team_index td {
  vertical-align: middle;
  padding: 20px 10px !important;
}
.team_index .copy-writes .text-muted {
  font-size: 16px;
  font-family: SF-Compact-Display-Light;
  color: #000 !important;
  font-weight: 600;
}
.team_index .copy-writes .text-muted1 {
  font-size: 9px;
  font-family: SF-Compact-Display-Thin;
  color: #000 !important;
  font-weight: 600;
}
.team_index .copy-writes .col-md-4 {
  line-height: 1;
}
.team_index .copy-writes footer .footer-icon {
  margin: 0 !important;
  vertical-align: top;
}
.team_index .copy-writes footer ul li.ms-3 {
  font-size: 10px;
  font-family: SF-Compact-Display-Thin;
  font-weight: 600;
  color: #000;
}
.team_index .table thead {
  background: #fff;
}
.team_index .table tbody tr:nth-child(odd) {
  background-color: #f5f5f5;
}
.team_index th,
.team_index td {
  text-align: left;
}
.team_index .team_index-heading .w25 {
  width: 10% !important;
}
.team_index th:nth-child(1),
.team_index td:nth-child(1) {
  text-align: left;
}
.team_index .table tbody td span.copywriting {
  font-size: 11px;
  background: #e7b949;
  color: white;
  padding: 2px 3px;
  font-family: SF-Compact-Display-Regular;
}
.team_index .table tbody td span.research {
  font-size: 11px;
  background: #10a5dc;
  color: white;
  padding: 2px 3px;
  font-family: SF-Compact-Display-Regular;
}
.team_index .table tbody td span.wordpress {
  font-size: 11px;
  background: #044c90;
  padding: 2px 3px;
  font-family: SF-Compact-Display-Regular;
  color: #fff;
}
.team_index .table tbody td span.graphic-design {
  font-size: 11px;
  background: #ff8e42;
  color: white;
  padding: 2px 3px;
  font-family: SF-Compact-Display-Regular;
}
.team_index .table tbody td span.htmlcss {
  font-size: 11px;
  background: #3f3bf9;
  padding: 2px 3px;
  font-family: SF-Compact-Display-Regular;
  color: #fff;
}
.team_index .table tbody td span.facebook {
  font-size: 11px;
  background: #044c90;
  color: white;
  padding: 2px 3px;
  font-family: SF-Compact-Display-Regular;
}
.team_index .table tbody td span.finance {
  font-size: 11px;
  background: #0bb5ab;
  color: white;
  padding: 2px 3px;
  font-family: SF-Compact-Display-Regular;
}
.team_index .admin-main-section button {
  width: 100%;
}
.team_index .admin-main-section a {
  text-decoration: none;
}
.team_index .admin-main-section a:hover {
  text-decoration: none;
}
