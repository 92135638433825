body {
  font-family: SF-Compact-Display-Medium;
  font-size: 16px;
}
.task .task-Block h1 {
  font-size: 18px;
  margin-bottom: 0;
}
.task .task-header {
  border: 1px solid #cbcbcd;
  border-radius: 5px;
}
.task .task-Block h1 small {
  font-size: 12px;
  padding-right: 10px;
}
.task .task-heading {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: #e0e0e0;
  padding: 15px 10px;
}
.task .bi {
  vertical-align: -0.125em;
  fill: currentColor;
}
.task div.nav button.nav-link {
  text-align: left;
  color: black;
}
.task div.nav button.nav-link.active {
  color: white;
}
.task .admin-main-section {
  background: white;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
}
.task .admin-main-section .nav {
  width: 25%;
  padding: 1rem 0;
}
.task .save-btn {
  float: right;
}
.task .save-btn button {
  font-size: 16px;
  font-family: SF-Compact-Display-Medium;
  color: #fff;
  background-color: #0085e0 !important;
  border-color: #0085e0 !important;
  padding: 5px 20px;
}
.task .save-btn button:active,
.task .save-btn button:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: 0 !important;
  border: none !important;
}
.task .container.bg-white {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.task .copy-writes .text-muted {
  font-size: 16px;
  font-family: SF-Compact-Display-Light;
  color: #000 !important;
  font-weight: 600;
}
.task .copy-writes .text-muted1 {
  font-size: 9px;
  font-family: SF-Compact-Display-Thin;
  color: #000 !important;
  font-weight: 600;
}
.task .copy-writes .col-md-4 {
  line-height: 1;
}
.task .copy-writes footer .footer-icon {
  margin: 0 !important;
  vertical-align: top;
}
.task .copy-writes footer ul li.ms-3 {
  font-size: 10px;
  font-family: SF-Compact-Display-Thin;
  font-weight: 600;
  color: #000;
}
.task .paddingblock {
  padding: 20px 20px 150px 20px;
}
.task .note_block textarea {
  border: 1px solid #e8e8e8 !important;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  resize: none;
  width: 100%;
  border-radius: 5px !important;
}
.task .editor label {
  padding-bottom: 10px;
  font-size: 18px;;
  font-family: SF-Compact-Display-Medium;
}
.task .checkbox {
  width: 12px !important;
  height: 12px !important;
  float: left !important;
  border-radius: 0 !important;
}
.task .form-check label {
  padding-left: 20px;
  display: block;
  font-size: 14px;
  font-family: SF-Compact-Display-Regular;
  margin-bottom: 0;
}
.task .currency {
  width: 25% !important;
  border-radius: 0;
  border: 1px solid #e8e8e8;
}
.task .currency option {
  font-size: 16px;
  font-family: SF-Compact-Display-Regular;
}
.task .rate {
  padding-right: 5px;
  font-size: 18px;;
  font-family: SF-Compact-Display-Medium;
}
.task .skill.label {
  font-size: 18px;;
  font-family: SF-Compact-Display-Medium;
  padding-bottom: 10px;
}
.task .checklistblock {
  padding: 10px 10px 57px 10px;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
}
.task .checklistblock .form-check {
  padding: 0;
}
.task .add-del-btn .btn-primary {
  border: none;
  background: #80afea;
  border-radius: 0;
  padding: 2px 10px;
  font-size: 14px;
  font-family: "SF-Compact-Display-Regular";
}
.task .add-del-btn .btn-danger {
  border: none;
  background: #ff6b72;
  border-radius: 0;
  padding: 2px 10px;
  font-size: 14px;
  font-family: "SF-Compact-Display-Regular";
}
.task .add-del-btn button:active,
.task .add-del-btn button:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: 0 !important;
  border: none !important;
}
.task .admin-main-section a {
  text-decoration: none;
}
.task .admin-main-section a:hover {
  text-decoration: none;
}
.task .admin-main-section button.nav-link {
  width: 100%;
}