body {
  font-family: SF-Compact-Display-Medium;
  font-size: 16px;
}
.project .project-Block h1 {
  font-size: 18px;
  font-family: SF-Compact-Display-Medium;
  margin-bottom: 0;
}
.project .project-Block h1 small {
  font-size: 12px;
  padding-right: 10px;
}
.project .project-header {
  border: 1px solid #cbcbcd;
  border-radius: 5px;
}
.project .project-heading {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: #e0e0e0;
  padding: 15px 30px;
}
.project .project-detail-section {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.project .project-detail-section .col-md-8 h4 {
  font-size: 18px;;
  padding: 15px 18px;
  font-family: SF-Compact-Display-Medium;
  margin-bottom: 0;
}
.project .client-info-block {
  border: 1px solid #cacacd;
  padding: 10px;
  margin: 0 18px;
  background: #fdfdfd;
}
.project .client-info-block .col6 {
  width: 25%;
}
.project .client-info-block .col6 p {
  font-size: 14px;
  margin-bottom: 0;
  font-family: SF-Compact-Display-Medium;
}
.project .client-info-block .col6.title-color p {
  color: #646464;
}
.project .project-header .project-detail-section .col-md-3 h3 {
  font-size: 16px;
  font-family: SF-Compact-Display-Regular;
  margin-bottom: 0;
}
.project .project-detail-section .col-md-3 {
  text-align: right;
  padding: 0 30px;
}
.project .project-detail-section .col-md-3 h3 {
  text-align: right;
  margin-left: auto;
  width: fit-content;
  padding: 2px 10px;
  background: #0790e0;
  border-radius: 5px;
  color: #fff;
}
.project .notes-paragraph-section {
  text-align: left;
}
.project .notes-paragraph-section p {
  font-size: 18px;;
  margin-bottom: 5px;
  font-family: SF-Compact-Display-Medium;
}
.project .notes-paragraph-section p span {
  font-size: 14px;
  font-family: SF-Compact-Display-Regular;
}
.project .notes-paragraph-section p span.cc {
  font-size: 18px;
  background: #1e9dbd;
  padding: 0 5px;
  font-family: SF-Compact-Display-Regular;
  color: #fff;
}
.project .project-header .project-detail-section .col-md-3 .modify-block {
  padding: 15px 0;
}
.project .projects-list-block td {
  font-size: 14px;
  font-family: SF-Compact-Display-Medium;
  margin-bottom: 0;
  border-bottom-width: 0;
}
.project .invoice-cal {
  padding: 25px 0;
}
.project .invoice-cal .add {
  width: 50px;
  font-size: 16px;
  font-family: SF-Compact-Display-Regular;
  background: #0b55b0;
  text-align: center;
  color: #fff;
  margin-bottom: 0;
}
.project .link-upload-block .invoice-cal {
  padding: 0 0 25px 0;
  margin: 0 18px;
  float: right;
}
.project .link-upload-block .invoice-cal .add-upload {
  width: 60px;
  font-size: 16px;
  font-family: SF-Compact-Display-Regular;
  background: #0b55b0;
  text-align: center;
  color: #fff;
  margin-bottom: 0;
}
.project .assign-to {
  font-size: 16px;
  background: #1e9dbd;
  padding: 0 5px;
  font-family: SF-Compact-Display-Regular;
  color: #fff;
  margin: 0 2px;
}
.project .assign-to-dropdown {
  position: absolute;
}
.project .assign-to-dropdown button {
  border: none;
  padding: 0 5px;
}
.project .projects-list-block {
  margin: 0 18px;
}
.project table.table {
  margin-bottom: 0;
}
.project .table-project-heading {
  font-size: 18px;;
  font-family: SF-Compact-Display-Medium;
  margin-bottom: 0;
}
.project .container.bg-white {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.project .projecct-name {
  font-size: 14px;
  font-family: SF-Compact-Display-Medium;
  margin-bottom: 0;
  border-bottom-width: 0;
  font-weight: 600;
}
.project .projects-block .projects-list-block .table-group-divider tr {
  border-top: 2px solid #aeaeae;
}
.project .projecct-name span {
  font-size: 14px;
  font-family: SF-Compact-Display-Regular;
  margin-bottom: 0;
  border-bottom-width: 0;
  font-weight: 400;
}
.project .projects-block .projects-list-block .table-project-heading {
  font-size: 14px;
  font-family: SF-Compact-Display-Semibold;
  margin-bottom: 0;
}
.project .projects-list-block .table-group-divider {
  border-top-color: #838383;
}
.project .projects-block .projects-list-block .table-group-divider {
  border-top-color: #aeaeae;
}
.project td,
.project th {
  vertical-align: middle;
  padding: 10px 0 !important;
}
.project .invoice-project-block {
  padding: 15px 0 0 0;
}
.project .copy-writes .text-muted {
  font-size: 16px;
  font-family: SF-Compact-Display-Light;
  color: #000 !important;
  font-weight: 600;
}
.project .copy-writes .text-muted1 {
  font-size: 9px;
  font-family: SF-Compact-Display-Thin;
  color: #000 !important;
  font-weight: 600;
}
.project .copy-writes .col-md-4 {
  line-height: 1;
}
.project .copy-writes footer .footer-icon {
  margin: 0 !important;
  vertical-align: top;
}
.project .copy-writes footer ul li.ms-3 {
  font-size: 10px;
  font-family: SF-Compact-Display-Thin;
  font-weight: 600;
  color: #000;
}
.project .checkbox-menu {
  border-radius: 0;
}
.project .checkbox-menu li {
  text-align: left;
}
.project .checkbox-menu li label {
  display: block;
  padding: 3px 10px;
  font-size: 14px;
  font-family: SF-Compact-Display-Regular;
  font-weight: 600;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
  margin: 0;
  transition: background-color 0.4s ease;
}
.project .checkbox-menu li input {
  margin: 0 5px;
  top: 2px;
  position: relative;
}
.project .checkbox-menu li label:focus,
.project .checkbox-menu li label:hover {
  background-color: #f5f5f5;
}
.project .updates-block {
  padding: 0;
  margin: 0 18px;
  background: #fdfdfd;
  border: 1px solid #e8e8e8;
  min-height: 80px;
}
.project .updates-block .updates-section {
  background: #f1f1f1;
  height: 30px;
}
.project .updates-block .updates-section h4 {
  padding: 6px 10px;
  font-size: 14px;
  font-family: SF-Compact-Display-Medium;
  margin-bottom: 0;
}
.project .updates-main-block {
  padding: 10px 0;
}
.project .updates-main-block h3 {
  padding-bottom: 10px;
  font-size: 18px;;
  font-family: SF-Compact-Display-Medium;
  margin-bottom: 0;
  font-weight: 600;
}
.project .onboard-block {
  padding-bottom: 20px;
  margin: 0 18px;
}
.project .onboard-block h1 {
  font-size: 18px;;
  font-family: SF-Compact-Display-Medium;
  margin-bottom: 0;
  font-weight: 700;
  padding-bottom: 10px;
}
.project .onboard-block textarea {
  resize: none;
  width: 100%;
  border: 1px solid #cacacd;
  overflow-y: scroll;
}
.project .link-upload-block h3 {
  margin: 0 18px;
}
.project .link-upload-block .link-updates {
  margin: 0 18px;
}
@media (min-width: 992px) {
  .project .client-info-block {
    min-width: 550px;
    width: 550px;
  }
  .project .projects-block .col-md-11 {
    padding-right: 50px;
  }
  .project .projects-block .col-md-11 .table td,
  .project .projects-block .col-md-11 .table th {
    width: 20%;
  }
}
