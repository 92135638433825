body {
  font-family: SF-Compact-Display-Medium;
  font-size: 16px;
}

.task_index .task_of_index-Block h1 {
  font-size: 18px;
  font-family: SF-Compact-Display-Medium;
  margin-bottom: 0;
}
.task_index .task_of_index-header {
  border: 1px solid #cbcbcd;
  border-radius: 5px;
}
.task_index .task_of_index-heading {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: #e0e0e0;
  padding: 15px 30px;
}
.task_index .bi {
  vertical-align: -0.125em;
  fill: currentColor;
}
.task_index div.nav button.nav-link {
  text-align: left;
  color: black;
}
.task_index div.nav button.nav-link.active {
  color: white;
}
.task_index .admin-main-section {
  background: white;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
}
.task_index .admin-main-section .nav {
  width: 25%;
  padding: 1rem 0;
}
.task_index .task_of_indexs-list-block td {
  font-size: 14px;
  font-family: SF-Compact-Display-Medium;
  margin-bottom: 0;
  border-bottom-width: 0;
}
.task_index .add button:active,
.task_index .add button:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: 0 !important;
  border: none !important;
}
.task_index .task_of_index-heading .add button {
  font-size: 14px;
  font-family: SF-Compact-Display-Regular;
  background: #0b55b0;
  text-align: center;
  color: #fff;
  margin-bottom: 0;
  border: none;
  border-radius: 0;
  padding: 3px 5px;
}
.task_index .task_of_indexs-list-block {
  margin: 0 0 190px 0;
}
.task_index table.table {
  margin-bottom: 0;
}
.task_index .table-task_of_index-heading {
  font-size: 18px;
  font-family: SF-Compact-Display-Medium;
  margin-bottom: 0;
}
.task_index .container.bg-white {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.task_index
  .projects-block
  .task_of_indexs-list-block
  .table-task_of_index-heading {
  font-size: 14px;
  font-family: SF-Compact-Display-Semibold;
  margin-bottom: 0;
}
.task_index .table .table-group-divider {
  border: none;
}
.task_index .projects-block .task_of_indexs-list-block .table-group-divider {
  border-top-color: #aeaeae;
}
.task_index th {
  vertical-align: middle;
  padding: 10px 30px !important;
}
.task_index td {
  vertical-align: middle;
  padding: 20px 30px !important;
}
.task_index .copy-writes .text-muted {
  font-size: 16px;
  font-family: SF-Compact-Display-Light;
  color: #000 !important;
  font-weight: 600;
}
.task_index .copy-writes .text-muted1 {
  font-size: 9px;
  font-family: SF-Compact-Display-Thin;
  color: #000 !important;
  font-weight: 600;
}
.task_index .copy-writes .col-md-4 {
  line-height: 1;
}
.task_index .copy-writes footer .footer-icon {
  margin: 0 !important;
  vertical-align: top;
}
.task_index .copy-writes footer ul li.ms-3 {
  font-size: 10px;
  font-family: SF-Compact-Display-Thin;
  font-weight: 600;
  color: #000;
}
.task_index .table thead {
  background: #fff;
}
.task_index .table tbody tr:nth-child(even) {
  background-color: #f5f5f5;
}
.task_index th,
.task_index td {
  text-align: center;
}
.task_index .task_of_index-heading .w25 {
  width: 10% !important;
}
.task_index th:nth-child(1),
.task_index td:nth-child(1) {
  text-align: left;
}
.task_index .table tbody td span.eccomerce {
  font-size: 14px;
  background: #6698dc;
  padding: 2px 3px;
  font-family: SF-Compact-Display-Regular;
}
.task_index .table tbody td span.research {
  font-size: 14px;
  background: #e7b949;
  padding: 2px 3px;
  font-family: SF-Compact-Display-Regular;
}
.task_index .table tbody td span.graphic-design {
  font-size: 14px;
  background: #ee6418;
  padding: 2px 3px;
  font-family: SF-Compact-Display-Regular;
}
/* .task_index .admin-main-section button {
  width: 100%;
} */
.task_index .admin-main-section a {
  text-decoration: none;
}
.task_index .admin-main-section a:hover {
  text-decoration: none;
}

.generateReport {
  font-size: 14px;
  font-family: SF-Compact-Display-Regular;
  background: #0b55b0;
  text-align: center;
  color: #fff;
  width: 100%;
  height: 90%;
  /* margin-bottom: 0; */
  border: none;
  border-radius: 0;
  /* padding: 3px 5px; */
}
.date_input_style {
  width: 170px !important;
  height: 41px !important;
  border: 1px solid #ccc !important;
}
