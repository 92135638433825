body {
  font-family: SF-Compact-Display-Medium;
  font-size: 16px;
}
.quote .quote-Block h1 {
  font-size: 18px;
  font-family: SF-Compact-Display-Medium;
  margin-bottom: 0;
  font-weight: bold;
}
.quote .quote-Block h1 small {
  font-size: 12px;
  padding-right: 10px;
}
.quote .quote-header {
  border: 1px solid #cbcbcd;
  border-radius: 5px;
}
.quote .quote-heading {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: #e0e0e0;
  padding: 15px 30px;
}
.quote .quote-detail-section {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.quote .quote-detail-section .col-md-8 h4 {
  font-size: 18px;
  padding: 15px 18px;
  font-family: SF-Compact-Display-Medium;
  margin-bottom: 0;
  font-weight: bold;
}
.quote .client-info-block {
  border: 1px solid #cacacd;
  padding: 10px;
  margin: 0 18px;
  background: #fdfdfd;
  padding-right: 20px;
  width: 60%;
}
/* .quote .client-info-block .col6 {
  width: 35%;
} */
.quote .client-info-block .col6 p {
  font-size: 14px;
  margin-bottom: 0;
  font-family: SF-Compact-Display-Medium;
}
.quote .client-info-block .col6.title-color p {
  color: #646464;
}
.quote .client-info-block .col7 {
  width: 65%;
}
.quote .client-info-block .col7 p {
  font-size: 14px;
  margin-bottom: 0;
  font-family: SF-Compact-Display-Medium;
}
.quote .client-info-block .col7.title-color p {
  color: #646464;
}

.quote .quote-header .quote-detail-section .col-md-3 h3 {
  font-size: 16px;
  font-family: SF-Compact-Display-Regular;
  margin-bottom: 0;
}
.quote .quote-detail-section .col-md-3 {
  text-align: right;
  padding: 0 30px;
}
.quote .quote-detail-section .col-md-3 h3 {
  text-align: right;
  margin-left: auto;
  width: fit-content;
  padding: 2px 10px;
  background: #3f3f3f;
  border-radius: 5px;
  color: #fff;
}
.quote .notes-paragraph-section {
  text-align: left;
}
.quote .notes-paragraph-section p {
  font-size: 18px;
  margin-bottom: 5px;
  font-family: SF-Compact-Display-Medium;
}
.quote .notes-paragraph-section p span {
  font-size: 14px;
  font-family: SF-Compact-Display-Regular;
}
.quote .notes-paragraph-section p span.cc {
  font-size: 18px;
  background: #1e9dbd;
  padding: 0 5px;
  font-family: SF-Compact-Display-Regular;
  color: #fff;
}
.quote .quote-header .quote-detail-section .col-md-3 .modify-block {
  padding: 15px 0;
}
.quote .quotes-list-block td {
  font-size: 14px;
  font-family: SF-Compact-Display-Medium;
  margin-bottom: 0;
  border-bottom-width: 0;
}
.quote .quotes-list-block .invoice-cal {
  padding: 10px 0;
}
.quote .quotes-list-block .col-4.subtotal {
  font-size: 14px;
  font-family: SF-Compact-Display-Medium;
}
.quote .quotes-list-block .col-4.tax {
  font-size: 14px;
  font-family: SF-Compact-Display-Regular;
}
.quote .quotes-list-block .col-4.total {
  font-size: 14px;
  font-family: SF-Compact-Display-Semibold;
}
.quote .quotes-list-block .invoice-cal .col-4 span {
  margin-left: 10px;
}
.quote .quotes-list-block .invoice-cal .col-4 {
  text-align: end;
  padding-right: 45px;
}
.quote .invoice-cal .col-6 {
  text-align: end;
}
.quote .invoice-cal .add {
  width: 50px;
  font-size: 16px;
  font-family: SF-Compact-Display-Regular;
  background: #0b55b0;
  text-align: center;
  color: #fff;
  margin-bottom: 0;
}
.quote .quotes-list-block {
  margin: 0 18px;
}
.quote table.table {
  margin-bottom: 0;
}
.quote .table-quote-heading {
  font-size: 18px;
  font-family: SF-Compact-Display-Medium;
  margin-bottom: 0;
}
.quote .container.bg-white {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.quote .projecct-name {
  font-size: 14px;
  font-family: SF-Compact-Display-Medium;
  margin-bottom: 0;
  border-bottom-width: 0;
  font-weight: 600;
}
.quote .projects-block .quotes-list-block .table-group-divider tr {
  border-top: 2px solid #aeaeae;
}
.quote .convert-text-block p {
  font-size: 14px;
  margin: 0 18px;
  padding: 100px 10px;
  font-family: SF-Compact-Display-Regular;
  margin-bottom: 0;
}
.quote .projecct-name span {
  font-size: 14px;
  font-family: SF-Compact-Display-Regular;
  margin-bottom: 0;
  border-bottom-width: 0;
  font-weight: 400;
}
.quote .projects-block .quotes-list-block .table-quote-heading {
  font-size: 14px;
  font-weight: bolder;
}
.quote-details {
  text-align: justify;
  max-width: 400px;
  min-width: 400px;
}
.quote-price {
  padding-left: 5px;
  min-width: 50px;
  text-align: center;
}
.quote .quotes-list-block .table-group-divider {
  border-top-color: #838383;
}
.quote .projects-block .quotes-list-block .table-group-divider {
  border-top-color: #aeaeae;
}
.quote td,
.quote th {
  vertical-align: middle;
  padding: 10px 0 !important;
}
.quote .invoice-quote-block {
  padding: 15px 0 0 0;
}
.quote .copy-writes .text-muted {
  font-size: 16px;
  font-family: SF-Compact-Display-Light;
  color: #000 !important;
  font-weight: 600;
}
.quote .copy-writes .text-muted1 {
  font-size: 9px;
  font-family: SF-Compact-Display-Thin;
  color: #000 !important;
  font-weight: 600;
}
.quote .copy-writes .col-md-4 {
  line-height: 1;
}
.quote .copy-writes footer .footer-icon {
  margin: 0 !important;
  vertical-align: top;
}
.quote .copy-writes footer ul li.ms-3 {
  font-size: 10px;
  font-family: SF-Compact-Display-Thin;
  font-weight: 600;
  color: #000;
}
@media (min-width: 992px) {
  .client-info-block {
    min-width: 250px;
    width: 250px;
  }
}

.react-datepicker__input-container input {
  width: 220px;
  border: 1px solid black;
  border-radius: 3px;
}
