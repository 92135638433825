body {
  font-family: SF-Compact-Display-Medium;
  font-size: 16px;
}

.task_page .task_page-Block h1 {
  font-size: 18px;
  font-family: SF-Compact-Display-Medium;
  margin-bottom: 0;
  font-weight: bold;
}
.task_page .task_page-Block h1 small {
  font-size: 12px;
  padding-right: 10px;
}
.task_page .task_page-header {
  border: 1px solid #cbcbcd;
  border-radius: 5px;
}
.task_page .task_page-heading {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: #e0e0e0;
  padding: 15px 30px;
}
.task_page .task_page-detail-section {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.task_page .task_page-detail-section .col-md-5 h4 {
  font-size: 18px;
  padding: 15px 18px;
  font-family: SF-Compact-Display-Medium;
  margin-bottom: 0;
  font-weight: bold;
}
.task_page .client-info-block {
  border: 1px solid #cacacd;
  background: #fdfdfd;
  padding: 10px;
  margin: 0 18px;
  padding-right: 20px;
  width: 85%;
}
/* .task_page .client-info-block .col6 {
  width: 35%;
} */
.task_page .client-info-block .col6 p {
  font-size: 14px;
  margin-bottom: 0;
  font-family: SF-Compact-Display-Medium;
}
.task_page .client-info-block .col6.title-color p {
  color: #646464;
}
.task_page .client-info-block .col7 {
  width: 65%;
}
.task_page .client-info-block .col7 p {
  font-size: 14px;
  margin-bottom: 0;
  font-family: SF-Compact-Display-Medium;
}
.task_page .client-info-block .col7.title-color p {
  color: #646464;
}
.task_page .notes-paragraph-section {
  text-align: left;
  border: 1px solid #cacacd;
  background: #fdfdfd;
  padding: 10px;
  word-break: break-word;
  max-height: 200px;
  overflow: auto;
}
.task_page .notes-paragraph-section p {
  margin: 0px auto;
}
.task_page .notes-paragraph-section p span {
  font-size: 14px;
  font-family: SF-Compact-Display-Regular;
}
/* .checklist-box {
  margin-left: 3.7%;
} */
.task_page .task_page-header .task_page-detail-section .col-md-7 .modify-block {
  padding: 15px 18px;
}
.task_page .task_page-header .task_page-detail-section .col-md-7 .notes-block {
  padding: 0 18px;
}
.task_page
  .task_page-header
  .task_page-detail-section
  .col-md-7
  .modify-block
  span {
  background: #50b83d;
  color: #fff;
  padding: 2px 7px;
  font-size: 14px;
}
.task_page
  .task_page-header
  .task_page-detail-section
  .col-md-7
  .modify-block
  h3 {
  font-size: 18px;
  font-family: SF-Compact-Display-Medium;
  margin-bottom: 0;
  font-weight: bold;
}
.task_page .invoice-cal {
  padding: 25px 0;
}
.task_page .link-upload-block .invoice-cal {
  padding: 0 0 25px 0;
  margin: 0 18px;
  float: right;
}
.task_page .link-upload-block .invoice-cal .add-upload {
  width: 60px;
  font-size: 16px;
  font-family: SF-Compact-Display-Regular;
  background: #0b55b0;
  text-align: center;
  color: #fff;
  margin-bottom: 0;
}
.task_page .container.bg-white {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.task_page .upload-link-section {
  padding: 20px 0 0 0;
}
.task_page .save-btn {
  padding: 50px 20px;
  float: right;
  margin-top: -30px;
}
.task_page .save-btn button {
  font-size: 16px;
  font-family: SF-Compact-Display-Medium;
  color: #fff;
  background-color: #0b55b0 !important;
  border-color: #0b55b0 !important;
  padding: 5px 20px;
}
.task_page .save-btn button:active,
.task_page .save-btn button:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: 0 !important;
  border: none !important;
}
.task_page .copy-writes .text-muted {
  font-size: 16px;
  font-family: SF-Compact-Display-Light;
  color: #000 !important;
  font-weight: 600;
}
.task_page .copy-writes .text-muted1 {
  font-size: 9px;
  font-family: SF-Compact-Display-Thin;
  color: #000 !important;
  font-weight: 600;
}
.task_page .copy-writes .col-md-5 {
  line-height: 1;
}
.task_page .copy-writes footer .footer-icon {
  margin: 0 !important;
  vertical-align: top;
}
.task_page .copy-writes footer ul li.ms-3 {
  font-size: 10px;
  font-family: SF-Compact-Display-Thin;
  font-weight: 600;
  color: #000;
}
.task_page .updates-block {
  padding: 0;
  margin: 0 18px;
  background: #fdfdfd;
  border: 1px solid #e8e8e8;
  min-height: 80px;
}
.task_page .tracker {
  margin: 10px 18px 10px 18px;
  font-size: 18px;
  font-family: SF-Compact-Display-Medium;
  font-weight: 700;
  padding: 5px 0;
}
.task_page .tracker-bar {
  margin: 10px 18px 10px 18px;
  font-size: 18px;
  font-family: SF-Compact-Display-Medium;
  font-weight: 700;
  border-bottom: 2px solid #e8e8e8;
  padding: 10px 0;
}
.task_page .tracker p {
  padding: 0;
  width: 10%;
  margin-bottom: 0;
}
.task_page .tracker-bar p {
  padding: 0;
  width: 5%;
  margin-bottom: 0;
}
.task_page .tracker-bar .success-bar {
  width: 50%;
  /* background: #76af43; */
  padding: 0;
}
.task_page .tracker-bar .success-bar p {
  font-size: 14px;
  font-family: SF-Compact-Display-Regular;
  color: #fff;
  padding: 3px 0;
  text-align: center;
  background: #3981c3;
  font-weight: 500;
  /* width: 90%; */
  text-align: left;
  padding-left: 20px;
}
.task_page .tracker img {
  width: 4%;
}
.task_page .tracker span {
  font-size: 14px;
  font-family: SF-Compact-Display-Regular;
  color: #6d6d6d;
  padding: 3px 0;
  width: 5%;
  text-align: center;
  background: #fafafa;
  font-weight: 500;
}
.task_page .tracker-bar span {
  font-size: 14px;
  font-family: SF-Compact-Display-Regular;
  padding: 3px 0;
  width: 5%;
  text-align: center;
  font-weight: 700;
}
.task_page .updates-block .updates-section {
  background: #f1f1f1;
  height: 30px;
}
.task_page .updates-block .updates-section h4 {
  padding: 6px 10px;
  font-size: 14px;
  font-family: SF-Compact-Display-Medium;
  margin-bottom: 0;
  font-weight: bold;
}
.task_page .updates-block .updates-paragraph-section p {
  /* padding: 5px 10px; */
  font-size: 14px;
  font-family: SF-Compact-Display-Regular;
  margin-bottom: 0;
}
.task_page .updates-block .updates-paragraph-section {
  padding-left: 10px;
  font-size: 14px;
  font-family: SF-Compact-Display-Regular;
  margin-bottom: 0;
}
.task_page .updates-main-block {
  padding: 10px 0;
}
.task_page .updates-main-block h3 {
  padding-bottom: 10px;
  font-size: 18px;
  font-family: SF-Compact-Display-Medium;
  margin-bottom: 0;
  font-weight: 600;
  margin: 0 18px;
}

.task_page .updates-paragraph-section .row .col-6 {
  padding: 10px 0;
}
.task_page .updates-paragraph-section .col-6 textarea {
  vertical-align: middle;
  border: 1px solid #e8e8e8;
  background: #fdfdfd;
  resize: none;
}
.task_page .onboard-block {
  padding-bottom: 20px;
  margin: 0 18px;
}
.task_page .onboard-block h1 {
  font-size: 18px;
  font-family: SF-Compact-Display-Medium;
  margin-bottom: 0;
  font-weight: 700;
  padding-bottom: 5px;
}
.task_page .marginleft {
  margin-left: auto;
}
.task_page .Services-section {
  padding: 20px 0;
}
.task_page .checklist {
  background: #fafafa;
  border: 1px solid #cacacd;
  padding: 5px;
}
.task_page .checklist input {
  vertical-align: middle;
}
.task_page .checklist label {
  font-size: 14px;
  font-family: SF-Compact-Display-Regular;
}
.task_page .onboard-block textarea {
  resize: none;
  width: 100%;
  border: 1px solid #cacacd;
}
.task_page .link-upload-block h3 {
  margin: 0 18px;
}
.task_page .link-upload-block .link-updates {
  margin: 0 18px;
}
