body {
  font-family: SF-Compact-Display-Medium;
  font-size: 16px;
}

.client_page .client_page-Block h1 {
  font-size: 18px;
  font-family: SF-Compact-Display-Medium;
  margin-bottom: 0;
  font-weight: bold;
}
.client_page .client_page-Block h1 small {
  font-size: 12px;
  padding-right: 10px;
}
.client_page .client_page-header {
  border: 1px solid #cbcbcd;
  border-radius: 5px;
}
.client_page .client_page-heading {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: #e0e0e0;
  padding: 15px 30px;
}
.client_page .client_page-detail-section {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.client_page .client_page-detail-section .col-md-10 h4 {
  font-size: 18px;
  padding: 15px 18px;
  font-weight: bold;
  font-family: SF-Compact-Display-Medium;
  margin-bottom: 0;
}
.info-heading {
  font-size: 18px;
  padding: 15px 18px;
  font-weight: bold;
  font-family: SF-Compact-Display-Medium;
  margin-bottom: 0;
}
.client_page .client-info-block {
  border: 1px solid #cacacd;
  /* padding: 10px; */
  margin: 0 18px;
  background: #fdfdfd;
}
/* .client_page .client-info-block .col6 {
  width: 35%;
} */
.client_page .client-info-block .col6 p {
  font-size: 14px;
  margin-bottom: 0;
  font-family: SF-Compact-Display-Medium;
}
.client_page .client-info-block .col6.title-color p {
  color: #646464;
}
.client_page .client-info-block .col7 {
  width: 65%;
}
.client_page .client-info-block .col7 p {
  font-size: 14px;
  margin-bottom: 0;
  font-family: SF-Compact-Display-Medium;
}
.client_page .client-info-block .col7.title-color p {
  color: #646464;
}
.client_page .client_page-header .client_page-detail-section .col-md-4 h3 {
  font-size: 10px;
  font-family: SF-Compact-Display-Regular;
  padding: 15px 18px 5px 18px;
  margin-bottom: 0;
}
.client_page
  .client_page-header
  .client_page-detail-section
  .col-md-2
  .notes-block {
  margin: 0 10px;
  background: #fdfdfd;
  border: 1px solid #e8e8e8;
  min-height: 200px;
}
.client_page
  .client_page-header
  .client_page-detail-section
  .col-md-4
  .notes-block
  .notes-heading-section {
  background: #f1f1f1;
  height: 30px;
}
.client_page
  .client_page-header
  .client_page-detail-section
  .col-md-4
  .notes-heading-section
  h4 {
  padding: 6px 10px;
  font-size: 14px;
  font-family: SF-Compact-Display-Medium;
  margin-bottom: 0;
}
.client_page
  .client_page-header
  .client_page-detail-section
  .col-md-4
  .notes-paragraph-section
  p {
  padding: 10px 20px;
  font-size: 14px;
  font-family: SF-Compact-Display-Regular;
  margin-bottom: 0;
}
.client_page .invoices-block td,
.client_page .client_pages-list-block td {
  font-size: 14px;
  font-family: SF-Compact-Display-Medium;
  margin-bottom: 0;
  border-bottom-width: 0;
}
.client_page .invoices-block tr:hover td,
.client_page .client_pages-list-block tr:hover td {
  background: #d3d3d3;
}
.client_page .invoices-block,
.client_page .client_pages-list-block {
  margin: 0 18px;
}

.client_page .invoices-block .table-group-divider,
.client_page .client_pages-list-block .table-group-divider {
  border-top-color: white;
}
.client_page td span.lost {
  background: rgb(240, 240, 5);
  padding: 3px;
  color: #fff;
}
.client_page td span.won {
  background: #68b152;
  padding: 3px;
  color: #fff;
}
.client_page td span.br {
  padding: 3px;
  margin-bottom: 4px;
  border: 1px solid grey;
  border-radius: 3px;
  background: white;
  margin-right: 5px;
  color: grey;
  display: inline-flex;
  flex-flow: row wrap;
  gap: 10px;
}
.client_page td {
  vertical-align: middle;
  padding: 10px 0 !important;
}
.client_page th {
  vertical-align: middle;
  /* padding: 10px 0 !important; */
  font-size: 14px;
  padding-left: 5px;
}
.client_page .invoice-client_page-block,
.client_page .projects-block {
  padding: 20px 0;
}
.client_page .updates-main-block {
  padding: 20px 0;
}
.client_page .updates-main-block h3 {
  padding-bottom: 10px;
  margin: 0 18px;
  font-size: 18px;
  font-family: SF-Compact-Display-Medium;
  margin-bottom: 0;
  font-weight: 600;
}
.client_page .updates-block {
  padding: 0;
  margin: 0 18px;
  background: #fdfdfd;
  border: 1px solid #e8e8e8;
  min-height: 80px;
}
.client_page .updates-block .updates-section {
  background: #f1f1f1;
  height: 30px;
}
.client_page .updates-block .updates-section h4 {
  padding: 6px 10px;
  font-size: 14px;
  font-family: SF-Compact-Display-Medium;
  margin-bottom: 0;
}
.client_page .updates-block .updates-paragraph-section p {
  padding: 0px 08px;
  font-size: 14px;
  font-family: SF-Compact-Display-Regular;
  margin-bottom: 0;
}
.client_page .save-btn {
  padding: 50px 20px;
  float: right;
}
.client_page .save-btn button {
  font-size: 16px;
  font-family: SF-Compact-Display-Medium;
  color: #fff;
  background-color: #0085e0 !important;
  border-color: #0085e0 !important;
  padding: 5px 20px;
}
.client_page .save-btn button:active,
.client_page .save-btn button:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: 0 !important;
  border: none !important;
}
.client_page .copy-writes .text-muted {
  font-size: 16px;
  font-family: SF-Compact-Display-Light;
  color: #000 !important;
  font-weight: 600;
}
.client_page .copy-writes .text-muted1 {
  font-size: 9px;
  font-family: SF-Compact-Display-Thin;
  color: #000 !important;
  font-weight: 600;
}
.client_page .copy-writes .col-md-4 {
  line-height: 1;
}
.client_page .copy-writes footer .footer-icon {
  margin: 0 !important;
  vertical-align: top;
}
.client_page .copy-writes footer ul li.ms-3 {
  font-size: 10px;
  font-family: SF-Compact-Display-Thin;
  font-weight: 600;
  color: #000;
}
.client_page .updates-paragraph-section .row .col-6 {
  padding: 10px 0;
}
.client_page .updates-paragraph-section .col-6 textarea {
  vertical-align: middle;
  border: 1px solid #e8e8e8;
  background: #fdfdfd;
  resize: none;
}
.table_client_page_headings {
  font-size: 18px;
  font-family: SF-Compact-Display-Regular;
  font-weight: bolder;
  margin-bottom: 8px;
}
.notes-paragraph-section p span.cc {
  font-size: 18px;
  background: #1e9dbd;
  padding: 0 5px;
  font-family: SF-Compact-Display-Regular;
  color: #fff;
}
@media (min-width: 992px) {
  .client_page .client-info-block {
    min-width: 270px;
    width: 280px;
  }
}
.react-datepicker__input-container input {
  width: 220px;
  border: 1px solid black;
  border-radius: 3px;
}
