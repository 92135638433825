body {
  font-family: SF-Compact-Display-Medium;
  font-size: 16px;
}

.project_list .project_list-Block h1 {
  font-size: 18px;
  font-family: SF-Compact-Display-Medium;
  margin-bottom: 0;
  font-weight: bold;
}
.project_list .project_list-header {
  border: 1px solid #cbcbcd;
  border-radius: 5px;
}
.project_list .project_list-heading {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  /* background: #e0e0e0; */
  padding: 15px 30px;
}
.project_list .project_list-Block.update .project_list-heading {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: #fff;
  padding: 15px 30px;
}
.project_list .project_list-Block.update .projects-block {
  padding: 0 30px 150px 30px;
}
.project_list
  .project_list-Block.update
  .projects-block
  .project_lists-list-block
  .table-group-divider {
  border-top-color: #e8e8e800 !important;
}
.project_list .project_list-Block.update .projects-block td,
.project_list .project_list-Block.update .projects-block th {
  padding: 10px 0 !important;
}
.project_list .project_lists-list-block td {
  font-size: 14px;
  font-family: SF-Compact-Display-Medium;
  margin-bottom: 0;
  border-bottom-width: 0;
}
.project_list table.table {
  margin-bottom: 0;
}
.project_list .table-project_list-heading {
  font-size: 18px;
  margin-bottom: 0;
}
.project_list .container.bg-white {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.project_list .projects-block {
  padding-bottom: 150px;
}
.project_list .projects-block .project_lists-list-block .table-group-divider {
  border-top-color: #e8e8e8 !important;
}
.project_list .project_list-Block .filter .col-md-12 {
  padding: 0;
}
.project_list .newblock span {
  background: #0064eb;
  padding: 2px 5px;
  color: white;
}
.project_list .statusactive span {
  background: #50b83c;
  padding: 2px 5px;
  color: white;
}
.project_list .projects-block .col-md-12 {
  padding: 0;
}
.project_list .filter p {
  padding: 10px 30px;
  font-size: 18px;
  font-family: SF-Compact-Display-Medium;
  border-bottom: 2px solid #e8e8e8;
  margin-bottom: 0;
}
.project_list .project_list-Block.update {
  padding-top: 30px;
}
.project_list .filter p span {
  padding: 2px 10px;
  font-size: 14px;
  font-family: SF-Compact-Display-Regular;
  background: #e3e3e3;
  border-radius: 25px;
}
.project_list
  .projects-block
  .project_lists-list-block
  .table-project_list-heading {
  font-size: 14px;
  margin-bottom: 0;
  font-weight: bold;
}
.project_list .project_lists-list-block .table-group-divider {
  border-top-color: #838383;
}
.project_list .projects-block .project_lists-list-block .table-group-divider {
  border-top-color: #aeaeae;
}
.project_list td,
.project_list th {
  vertical-align: middle;
  padding: 10px !important;
}
.project_list .copy-writes .text-muted {
  font-size: 16px;
  font-family: SF-Compact-Display-Light;
  color: #000 !important;
  font-weight: 600;
}
.project_list .copy-writes .text-muted1 {
  font-size: 9px;
  font-family: SF-Compact-Display-Thin;
  color: #000 !important;
  font-weight: 600;
}
.project_list .copy-writes .col-md-4 {
  line-height: 1;
}
.project_list .copy-writes footer .footer-icon {
  margin: 0 !important;
  vertical-align: top;
}
.project_list .copy-writes footer ul li.ms-3 {
  font-size: 10px;
  font-family: SF-Compact-Display-Thin;
  font-weight: 600;
  color: #000;
}
.project_list .assign-to {
  padding: 2px;
  margin-bottom: 4px;
  border: 1px solid grey;
  border-radius: 3px;
  background: white;
  margin-right: 5px;
  color: grey;
  display: inline-flex;
  flex-flow: row wrap;
  gap: 10px;
  font-size: 12px;
}

.project_list .updates-block {
  padding: 0;
  margin: 0 18px;
  background: #fdfdfd;
  border: 1px solid #e8e8e8;
  min-height: 80px;
  font-size: 14px;
}

.project_list .updates-block .updates-section {
  background: #f1f1f1;
  height: 30px;
}
.project_list .updates-block .updates-section:hover {
  background: #d3d3d3;
}
.project_list .updates-block .updates-section h4 {
  padding: 6px 10px;
  font-size: 14px;
  font-weight: bold;
  font-family: SF-Compact-Display-Medium;
  margin-bottom: 0;
}
.project_list .updates-block .updates-paragraph-section p {
  padding: 0px 20px;
  font-size: 14px;
  font-family: SF-Compact-Display-Regular;
  margin-bottom: 0;
}
.project_list .updates-main-block {
  margin-left: -18px;
  margin-right: -18px;
}
.project_list .updates-main-block h3 {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 16px;
  font-family: SF-Compact-Display-Medium;
  margin-bottom: 0;
  font-weight: 600;
  margin: 0 18px;
}
.project_list .updates-paragraph-section .row .col-6 {
  padding: 10px 0;
}
.project_list .updates-paragraph-section .col-6 textarea {
  vertical-align: middle;
  border: 1px solid #e8e8e8;
  background: #fdfdfd;
  resize: none;
}
.proejct-row:hover {
  background-color: #d3d3d3;
}
