@font-face {
  font-family: SF-Compact-Display-Medium;
  src: url(../../public/assests/font/FontsFree-Net-SF-Compact-Display-Medium.ttf);
}
@font-face {
  font-family: SF-Compact-Display-Regular;
  src: url(../../public/assests/font/FontsFree-Net-SFCompactDisplay-Regular.ttf);
}
@font-face {
  font-family: SF-Compact-Display-Light;
  src: url(../../public/assests/font/SF-Compact-Display-Light.otf);
}
@font-face {
  font-family: SF-Compact-Display-Thin;
  src: url(../../public/assests/font/SF-Compact-Display-Thin.otf);
}
@font-face {
  font-family: SF-Compact-Display-Semibold;
  src: url(../../public/assests/font/SF-Compact-Display-Semibold.otf);
}
@font-face {
  font-family: Inter-Regular;
  src: url(../../public/assests/font/Inter-Regular.otf);
}
body {
  background-image: url(../../public/assests/img/login-bg.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100%;
}
.header {
  padding: 25px 0;
}
.header nav.navbar {
  border-radius: 5px;
}
.header img.logo {
  width: auto !important;
  height: 25px !important;
}
.header a.navbar-brand {
  font-size: 21px;
}
.header a.navbar-brand span {
  font-family: SF-Compact-Display-Medium;
  font-size: 16px;
  margin-top: 7px;
  margin-left: -8px;
}
.header .navbar-brand {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.header .navbar-brand span {
  margin-left: -10px;
}
#navbarText .navbar-nav {
  width: 100%;
  justify-content: end;
}
.header #navbarText .navbar-nav.icon {
  width: auto;
}
.header ul.navbar-nav li a {
  color: #000;
}
@media (min-width: 992px) {
  .header ul.navbar-nav {
    margin-right: 50px;
  }
  .header ul.navbar-nav.icon {
    margin-right: 0;
  }
  .header ul.navbar-nav li {
    font-family: Inter-Regular;
    font-size: 18px;
    margin: 0 20px;
  }
  .header ul.navbar-nav.icon li {
    margin: 0 5px;
  }
}
.notification {
  position: relative;
  display: inline-block;
  border-radius: 2px;
}
.notification .badge {
  position: absolute;
  top: -5px;
  right: -5px;
  padding: 2px 5px;
  border-radius: 50%;
  background-image: linear-gradient(#ff9d9d, #aa1313);
  color: #fff;
  font-family: SF-Compact-Display-Medium;
  font-weight: 400;
  font-size: 12px;
}
.dropdown-toggle::after {
  display: none !important;
}
/* .toggle-dropdown:hover .toggle-dropdown-menu {
  display: block;
  margin-top: 0;
}
.dropdown1:hover .dropdown-2 {
  pointer-events: none !important;
} */
.notification-div:hover {
  background: #d3d3d3;
}
.mydivouter{
  position:relative;
  }
  .mydivoverlap{
  position: relative;
  z-index: 1;
  }
  .mybuttonoverlap{
  position: absolute;
  z-index: 2;
  top: 0;
  display: none;
  left: 93%;	
  }
  .mydivouter:hover .mybuttonoverlap{ 
  display:block;
  }
