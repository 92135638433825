body {
  font-family: SF-Compact-Display-Medium;
  font-size: 16px;
}

.skills-Block h1 {
  font-size: 18px;
  font-family: SF-Compact-Display-Medium;
  margin-bottom: 0;
}
.skills-header {
  border: 1px solid #cbcbcd;
  border-radius: 5px;
}
.skills-heading {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: #e0e0e0;
  padding: 15px 30px;
}
.bi {
  vertical-align: -0.125em;
  fill: currentColor;
}
.skills div.nav button.nav-link {
  text-align: left;
  color: black;
}
.skills div.nav button.nav-link.active {
  color: white;
}
.skills .admin-main-section {
  background: white;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
}
.skills .admin-main-section .nav {
  width: 25%;
  padding: 1rem 0;
}
.skills-list-block td {
  font-size: 14px;
  font-family: SF-Compact-Display-Medium;
  margin-bottom: 0;
  border-bottom-width: 0;
}
.skills .add button:active,
.skills .add button:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: 0 !important;
  border: none !important;
}
.skills-heading .add button {
  font-size: 16px;
  font-family: SF-Compact-Display-Regular;
  background: #0b55b0;
  text-align: center;
  color: #fff;
  margin-bottom: 0;
  border: none;
  border-radius: 0;
  padding: 3px 5px;
}
.skills-list-block {
  margin: 0 0 190px 0;
}
.skills table.table {
  margin-bottom: 0;
}
.skills .container.bg-white {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.skills .table .table-group-divider {
  border: none;
}
.skills .projects-block .skills-list-block .table-group-divider {
  border-top-color: #aeaeae;
}
.skills td {
  vertical-align: middle;
  padding: 20px 30px !important;
}
.skills .copy-writes .text-muted {
  font-size: 16px;
  font-family: SF-Compact-Display-Light;
  color: #000 !important;
  font-weight: 600;
}
.skills .copy-writes .text-muted1 {
  font-size: 9px;
  font-family: SF-Compact-Display-Thin;
  color: #000 !important;
  font-weight: 600;
}
.skills .copy-writes .col-md-4 {
  line-height: 1;
}
.skills .copy-writes footer .footer-icon {
  margin: 0 !important;
  vertical-align: top;
}
.skills .copy-writes footer ul li.ms-3 {
  font-size: 10px;
  font-family: SF-Compact-Display-Thin;
  font-weight: 600;
  color: #000;
}
.skills .table thead {
  background: #fff;
}
.skills .table tbody tr:nth-child(even) {
  background-color: #f5f5f5;
}
.skills td {
  text-align: center;
}
.skills-heading .w25 {
  width: 10% !important;
}
.skills td:nth-child(1) {
  text-align: left;
}
.skills .admin-main-section button {
  width: 100%;
}
.skills .admin-main-section a {
  text-decoration: none;
}
.skills .admin-main-section a:hover {
  text-decoration: none;
}
.ReactModal__Overlay {
  background-color: #11ffee00 !important;
}
/* .ReactModalPortal {
  background-color: red !important;
} */
